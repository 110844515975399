import { Grid, Typography } from "@material-ui/core"
import React, { ReactElement } from "react"
import InstagramEmbed from "react-instagram-embed"

import BlogPost from "../../../../components/BlogPost"
import { Link } from "../../../../components/Link"
import { SearchResults } from "../../../../components/SearchResults"

interface Props {
  data: any
  location: Location
  pageContext: any
}

export default function Post(props: Props): ReactElement {
  const { location, pageContext } = props
  return (
    <BlogPost
      location={location}
      pageContext={pageContext}
      title="Hellblaue Taschen"
      category="Frühlingtrends 2020"
    >
      <Grid container={true} spacing={1}>
        <Grid item={true} xs={12}>
          <Typography paragraph={true}>
            Sowie viele Influencerinnen, Models und Promigäste ist die bekannte Influencerin Tamara
            Kalinic bei der Fashion Week in Mailand unterwegs. Tamara Kalinic sitzt im Restaurant
            des Galleria Vik Milano Hotels. Als Accessoire hat die hübsche Influencerin eine Bottega
            Veneta Jodie. Eine abgerundete{" "}
            <Link href="/hobos/" underline="always">
              Hobo Bag
            </Link>{" "}
            aus Interecciato Nappaleder. Eine tolle Form mit Knotendetails am Henkel. Die Farbe der{" "}
            <Link href="/handtaschen/" underline="always">
              Handtasche
            </Link>{" "}
            ist ICE, helles blau. Hellblaue Handtaschen sind diesen Frühling angesagt und können zu
            knalligen Farben kombiniert werden. Die Handtaschen von Bottega Veneta in hellem blau
            sind derzeit im Trend. Auch Model Mandy Bork ist in Mailand mit einer hellblauen{" "}
            <Link href="/umhaengetaschen/" underline="always">
              Umhhängetasche
            </Link>{" "}
            , genauer gesagt einer Padded Cassette von Bottega Veneta, bei der Fashion Week
            unterwegs. Sie hat die gepolsterte Umhängetasche mit einem hellblauen Blazer und
            hellblauer Bermuda-Shorts kombiniert. Egal ob mit knalligen oder soften Farben
            kombiniert, die hellblaue Tasche ist IN. Wir haben für euch günstigere Alternativen
            hellblauer Taschen im Gegensatz zu den{" "}
            <Link href="/luxustaschen/" underline="always">
              luxuriösen Handtaschen
            </Link>{" "}
            herausgesucht.
          </Typography>
        </Grid>
      </Grid>

      <Grid container={true} spacing={1}>
        <Grid item={true} xs={12} md={6}>
          <SearchResults
            breakpoints={{
              lg: 6,
              md: 6,
              xs: 6,
            }}
            results={[
              {
                href: "https://www.awin1.com/pclick.php?p=25727167793&a=667803&m=14336",
                images: [
                  {
                    alt: "Ein Bild einer Usha Schultertasche",
                    src:
                      "https://images.buybags.de/fit-in/1200x1200/filters:fill(white):autojpg()/products/a8a43dd627414453b2d692c55d53321e.jpg",
                    title: "Usha Schultertasche",
                  },
                ],
                name: "Usha Schultertasche",
                price: 79.95,
              },

              {
                href: "https://www.awin1.com/pclick.php?p=25806351091&a=667803&m=11590",
                images: [
                  {
                    alt: "Ein Bild einer Longchamp Shopper Le Pliage Club S in grün",
                    src:
                      "https://images.buybags.de/fit-in/1200x1200/filters:fill(white):autojpg()/products/9a86ef3f49f9e9fc22734dc0d049759a.jpg",
                    title: "Longchamp Shopper Le Pliage Club S gruen",
                  },
                ],
                name: "Longchamp Shopper Le Pliage Club S",
                price: 95,
              },

              {
                href: "https://www.awin1.com/pclick.php?p=25658676615&a=667803&m=11590",
                images: [
                  {
                    alt: "Ein Bild einer Marccain Umhängetasche in blau",
                    src:
                      "https://images.buybags.de/fit-in/1200x1200/filters:fill(white):autojpg()/products/42cebb274ec0ed49b7df3cf70a7070da.jpg",
                    title: "Marccain Umhängetasche blau",
                  },
                ],
                name: "Marccain Umhängetasche",
                price: 249.99,
              },

              {
                href: "https://www.awin1.com/pclick.php?p=25884027263&a=667803&m=14716",
                images: [
                  {
                    alt: "Ein Bild einer INYATI Handtasche mit Griff in Marmor-Optik Modell 'Coco'",
                    src:
                      "https://images.buybags.de/fit-in/1200x1200/filters:fill(white):autojpg()/products/277844071131b6d94f23d4e68e19d69d.jpg",
                    title: "INYATI Handtasche",
                  },
                ],
                name: "INYATI Handtasche mit Griff in Marmor-Optik Modell 'Coco'",
                price: 79.99,
              },
            ]}
          />
        </Grid>
        <Grid item={true} xs={12} md={6}>
          <InstagramEmbed
            url="https://www.instagram.com/p/B8x-RGYh8oo/"
            hideCaption={true}
            injectScript={true}
          />
        </Grid>
      </Grid>

      <Grid container={true} spacing={1}>
        <Grid item={true} xs={12} md={6}>
          <InstagramEmbed
            url="https://www.instagram.com/p/B89Q1JIIRsy/"
            hideCaption={true}
            injectScript={true}
          />
        </Grid>
        <Grid item={true} xs={12} md={6}>
          <SearchResults
            breakpoints={{
              lg: 6,
              md: 6,
              xs: 6,
            }}
            results={[
              {
                href: "https://www.awin1.com/pclick.php?p=25579208227&a=667803&m=11590",
                images: [
                  {
                    alt: "Ein Bild einer Coccinelle Umhängetasche in blau",
                    src:
                      "https://images.buybags.de/fit-in/1200x1200/filters:fill(white):autojpg()/products/9347f3de53474040f34ff5b584c101ca.jpg",
                    title: "Coccinelle Umhängetasche blau",
                  },
                ],
                name: "Coccinelle Umhängetasche blau",
                price: 270,
              },

              {
                href: "https://www.awin1.com/pclick.php?p=25884027269&a=667803&m=14716",
                images: [
                  {
                    alt: "Ein Bild einer INYATI Hobo Bag mit Zierquaste Modell 'Cléo'",
                    src:
                      "https://images.buybags.de/fit-in/1200x1200/filters:fill(white):autojpg()/products/6f394f2890ff03615ff7156c5e7df70a.jpg",
                    title: "INYATI Hobo Bag mit Zierquaste Modell 'Cléo'",
                  },
                ],
                name: "INYATI Hobo Bag mit Zierquaste Modell 'Cléo'",
                price: 69.99,
              },

              {
                href: "https://www.awin1.com/pclick.php?p=23520296651&a=667803&m=14716",
                images: [
                  {
                    alt: "Ein Bild einer REBECCA MINKOFF Micro Bag aus Nubukleder",
                    src:
                      "https://images.buybags.de/fit-in/1200x1200/filters:fill(white):autojpg()/products/16244475def71bde750dc99d6314ad46.jpg",
                    title: "REBECCA MINKOFF Micro Bag aus Nubukleder",
                  },
                ],
                name: "REBECCA MINKOFF Micro Bag aus Nubukleder",
                price: 149.99,
              },

              {
                href: "https://www.awin1.com/pclick.php?p=25833871483&a=667803&m=14336",
                images: [
                  {
                    alt: "Ein Bild einer myMo Schultertasche",
                    src:
                      "https://images.buybags.de/fit-in/1200x1200/filters:fill(white):autojpg()/products/b6b78e7d2fd1d11f653aec569e70a516.jpg",
                    title: "myMo Schultertasche",
                  },
                ],
                name: "myMo Schultertasche",
                price: 79.95,
              },
            ]}
          />
        </Grid>
      </Grid>
    </BlogPost>
  )
}
